import(/* webpackMode: "eager", webpackExports: ["PostHogPageview","PostHogProvider"] */ "/vercel/path0/apps/website/app/posthog-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/website/components/Background.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/website/components/Header.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/website/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"IBM_Plex_Sans\",\"arguments\":[{\"display\":\"swap\",\"subsets\":[\"latin\"],\"variable\":\"--font-ibm-plex-sans\",\"weight\":[\"100\",\"200\",\"300\",\"400\",\"500\",\"600\",\"700\"]}],\"variableName\":\"fontSans\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"display\":\"swap\",\"variable\":\"--font-input-mono\",\"declarations\":[{\"prop\":\"ascent-override\",\"value\":\"95%\"}],\"src\":[{\"weight\":\"250\",\"style\":\"normal\",\"path\":\"../public/fonts/InputMono-Thin.woff2\"},{\"weight\":\"250\",\"style\":\"italic\",\"path\":\"../public/fonts/InputMono-ThinItalic.woff2\"},{\"weight\":\"275\",\"style\":\"normal\",\"path\":\"../public/fonts/InputMono-ExtraLight.woff2\"},{\"weight\":\"275\",\"style\":\"italic\",\"path\":\"../public/fonts/InputMono-ExtraLightItalic.woff2\"},{\"weight\":\"300\",\"style\":\"normal\",\"path\":\"../public/fonts/InputMono-Light.woff2\"},{\"weight\":\"300\",\"style\":\"italic\",\"path\":\"../public/fonts/InputMono-LightItalic.woff2\"},{\"weight\":\"400\",\"style\":\"normal\",\"path\":\"../public/fonts/InputMono-Regular.woff2\"},{\"weight\":\"400\",\"style\":\"italic\",\"path\":\"../public/fonts/InputMono-Italic.woff2\"},{\"weight\":\"500\",\"style\":\"normal\",\"path\":\"../public/fonts/InputMono-Medium.woff2\"},{\"weight\":\"500\",\"style\":\"italic\",\"path\":\"../public/fonts/InputMono-MediumItalic.woff2\"},{\"weight\":\"700\",\"style\":\"normal\",\"path\":\"../public/fonts/InputMono-Bold.woff2\"},{\"weight\":\"700\",\"style\":\"italic\",\"path\":\"../public/fonts/InputMono-BoldItalic.woff2\"},{\"weight\":\"900\",\"style\":\"normal\",\"path\":\"../public/fonts/InputMono-Black.woff2\"},{\"weight\":\"900\",\"style\":\"italic\",\"path\":\"../public/fonts/InputMono-BlackItalic.woff2\"}]}],\"variableName\":\"fontMono\"}");
