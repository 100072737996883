import { SVGProps } from 'react';
import clsx from 'clsx';

export default function Logo({ className, ...props }: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="100"
      height="32"
      viewBox="0 0 100 32"
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      className={clsx('text-contrast', className)}
      {...props}
    >
      <title>Artillery</title>
      <path d="m20 16.3 1.5-1.6 1.5 1.4-9.2 9.2-1.4-1.4 4.2-4.3-4.2-4.2L8 19.6l-1.4-1.4L15.9 9l1.4 1.5-1.6 1.5 4.3 4.3Zm-2 2L13.8 14l.7-.7 4.2 4.2-.7.7Z" />
      <path d="M24 8 9.3 13l2 2 9.5-3.8-3.9 9.5 2 2L24 8Zm11.1 15 4.7-13.1h3L47.3 23h-2.6l-1-3.1h-5.1l-1 3.1H35Zm4.2-5h3.8l-.6-1.9a282.1 282.1 0 0 1-1.3-4.1 176.4 176.4 0 0 1-1.2 4.1l-.7 1.9Zm9.2 5v-9.3h2.1v1.6c.2-.6.5-1 .8-1.2.4-.3.9-.5 1.5-.5h.7v2a9 9 0 0 0-1 0c-.6 0-1 .1-1.4.5-.3.4-.5 1-.5 1.6V23h-2.2ZM60 13.7v1.8h-1.9v4.8c0 .4 0 .6.2.7.2.2.4.2.8.2h1V23h-1.4c-1 0-1.7-.2-2.1-.6-.5-.4-.7-1-.7-1.7v-5.2h-1.6v-1.8H56v-2.5h2.2v2.5h2Zm1.4 9.3v-9.3h2.2V23h-2.2Zm1.1-10.7c-.4 0-.7 0-1-.3-.2-.3-.3-.6-.3-1 0-.3.1-.6.4-.8.2-.3.5-.4 1-.4.3 0 .6.1.9.4.2.2.3.5.3.9 0 .3 0 .6-.3.9-.3.2-.6.3-1 .3ZM68 10v13h-2.2V9.9H68Zm4.2 0v13H70V9.9h2Zm6 13.3c-.9 0-1.7-.2-2.4-.6-.6-.4-1.2-1-1.5-1.7-.4-.8-.6-1.6-.6-2.5 0-1 .2-1.8.6-2.5a4.2 4.2 0 0 1 3.9-2.4c.9 0 1.6.2 2.3.6.7.4 1.2 1 1.6 1.7.3.7.5 1.6.5 2.5v.6h-6.7c0 .8.3 1.4.7 1.9.4.4 1 .7 1.7.7.6 0 1-.2 1.4-.4.4-.2.6-.6.7-1h2.1a3 3 0 0 1-.8 1.6 4 4 0 0 1-1.5 1.1 5 5 0 0 1-2 .4Zm-2.3-5.8h4.6a2.2 2.2 0 0 0-2.3-2.1c-.6 0-1.2.2-1.6.5a3 3 0 0 0-.7 1.6ZM84 23v-9.3h2.2v1.6c.2-.6.4-1 .8-1.2.4-.3.8-.5 1.4-.5h.8v2a9 9 0 0 0-1 0c-.6 0-1 .1-1.4.5-.4.4-.6 1-.6 1.6V23H84Zm6.8 3.7v-1.8H92c.3 0 .6-.1.7-.3.2-.1.3-.4.5-.8l.3-.9-3.6-9.2h2.4l1.7 4.9a71 71 0 0 1 .7 2l.6-2 1.7-4.9h2.3l-4.1 10.8c-.6 1.5-1.5 2.2-2.8 2.2h-1.5Z" />
    </svg>
  );
}
