'use client';

import clsx from 'clsx';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import Logo from '@/components/Logo';
import Button from '@/components/Button';
import GitHubStars from '@/components/GitHubStars';

export const NAVIGATION = [
  { title: 'Home', href: '/' },
  { title: 'Blog', href: '/blog' },
  { title: 'Changelog', href: '/changelog' },
  { title: 'Docs', href: '/docs' },
];

export default function Header() {
  const pathname = usePathname();

  return (
    <header className="sticky top-0 z-40">
      <ProgressiveBlur />
      <div className="container relative z-10 flex flex-wrap items-center justify-between gap-y-3 py-4 text-xs sm:py-6 md:gap-y-6">
        <Link href="/" className="-ms-1.5 -mt-1">
          <Logo />
        </Link>
        <nav className="order-last flex w-full items-center gap-3 md:order-none md:w-auto">
          {NAVIGATION.map(({ href, title }) => {
            const isActive =
              href === '/' ? pathname === href : pathname?.startsWith(href);
            return (
              <Link
                key={title}
                href={href}
                className={clsx(
                  'flex-grow rounded border border-whiteA-1 px-3 py-2 text-center font-mono uppercase transition-colors',
                  isActive
                    ? 'border-whiteA-1 bg-whiteA-1 font-medium text-contrast backdrop-blur'
                    : 'text-slate-11 hover:bg-whiteA-1 hover:text-contrast hover:backdrop-blur md:border-transparent',
                )}
              >
                {title}
              </Link>
            );
          })}
        </nav>
        <div className="inline-flex items-center gap-3">
          <GitHubStars />
          <Button href="https://app.artillery.io" variant="primary">
            Sign in
          </Button>
        </div>
      </div>
    </header>
  );
}

const ProgressiveBlur = () =>
  [...Array(8)].map((_, index) => {
    const backdropFilter = `blur(${0.15 * 2 ** index}px)`;
    const maskImage = `linear-gradient(to top,
          transparent ${12.5 * index}%,
          black ${12.5 * index + 12.5}%,
          black ${12.5 * index + 25}%,
          transparent ${12.5 * (index + 1)}%
        )`;

    return (
      <div
        key={index}
        aria-hidden
        className="pointer-events-none absolute inset-x-0 -bottom-16 top-0 sm:-bottom-10"
        style={{
          // prettier-ignore
          zIndex: index + 1,
          backdropFilter,
          maskImage,
          WebkitBackdropFilter: backdropFilter,
          WebkitMaskImage: maskImage,
        }}
      />
    );
  });
